import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FormProvider, useFormContext } from './FormContext';
import header from './robgleeson118-01.jpg';
import './Form.css'; // Import the CSS file for styling

const IncomesStep = ({ incomes }) => {
  const {
    selectedIncome,
    setSelectedIncome,
    incomeAmount,
    handleIncomeChange,
    handleIncomeAmountChange,
    handleAddIncome,
    addedIncomes,
    handleDeleteIncome
  } = useFormContext();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddIncome();
    }
  }

  return (
    <div className="step">
      <h2
        style={{
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
          padding: 20,
          borderRadius: 10
        }}
      >
        Income
      </h2>
      <div className="accordion">
        <p style={{ padding: 10 }}>If you are part of a couple please supply details for your spouse / partner. Your net weekly income should be provided, which
          means your weekly income after tax, PRSI or other deductions have been deducted.</p>
        {addedIncomes.length > 0 && (
          <div className="added-items">
            {/* <p>Added Incomes:</p> */}
            {addedIncomes.map((income, index) => (
              <div key={index} className="added-item">
                <p>
                  {income.type}: {`€${Number(income.amount).toLocaleString()}`}
                  <button
                    onClick={() => handleDeleteIncome(index)}
                    style={{
                      backgroundColor: '#ff5757',
                      color: '#fff',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      fontSize: '14px',
                      marginLeft: '8px',
                    }}
                  >
                    Delete
                  </button>
                </p>
              </div>
            ))}
          </div>
        )}
        {!selectedIncome && (
          <button
            style={{ backgroundColor: 'var(--accent-color)', borderRadius: 10, textAlign: 'center', color: 'var(--primary-color)' }}
            className={`accordion-btn ${selectedIncome ? 'active' : ''}`}
            onClick={() => setSelectedIncome(selectedIncome ? '' : incomes[0])}
          >
            {!addedIncomes.length ? 'Add Income' : 'Add additional Income'}
          </button>
        )}
        <div className={`panel ${selectedIncome ? 'active' : ''}`}>
          <select
            onChange={(e) => handleIncomeChange(e.target.value)}
            value={selectedIncome}
            style={{ backgroundColor: 'var(--secondary-color)', color: 'var(--primary-color)' }}
          >
            <option value="">{selectedIncome ? 'Change Type' : 'Select Type'}</option>
            {incomes.map((income, index) => (
              <option key={index} value={income}>
                {income}
              </option>
            ))}
          </select>
          {selectedIncome && (
            <div className="input-group">
              <input
                type="number"
                placeholder="Enter Amount per week"
                onChange={handleIncomeAmountChange}
                value={incomeAmount}
                style={{ backgroundColor: 'var(--secondary-color)', color: 'var(--primary-color)' }}
                onKeyDown={handleKeyDown}
              />
              <button
                onClick={handleAddIncome}
                style={{
                  backgroundColor: 'var(--accent-color)',
                  color: 'var(--primary-color)',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontSize: '16px',
                }}
              >
                Add
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CashAssetsStep = ({ cashAssets }) => {
  const {
    selectedCashAsset,
    setSelectedCashAsset,
    cashAssetAmount,
    handleCashAssetChange,
    handleCashAssetAmountChange,
    handleAddCashAsset,
    addedCashAssets,
    handleDeleteCashAsset,
  } = useFormContext();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddCashAsset();
    }
  }

  return (
    <div className="step">
      <h2
        style={{
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
          padding: 20,
          borderRadius: 10
        }}
      >
        Cash Assets
      </h2>
      <div className="accordion">
        <p style={{ padding: 10 }}>Details of all cash assets, giving the total balance amounts for you and your spouse or
          partner</p>
        {addedCashAssets.length > 0 && (
          <div className="added-items">
            {addedCashAssets.map((asset, index) => {
              return (
                <div key={index} className="added-item">
                  <p>
                    {asset.type}: {`€${Number(asset.amount).toLocaleString()}`}
                    <button onClick={() => handleDeleteCashAsset(index)}>Delete</button>
                  </p>
                </div>
              )
            })}
          </div>
        )}
        {!selectedCashAsset && (
          <button
            style={{ backgroundColor: 'var(--accent-color)', borderRadius: 10, textAlign: 'center', color: 'var(--primary-color)' }}
            className={`accordion-btn ${selectedCashAsset ? 'active' : ''}`}
            onClick={() => setSelectedCashAsset(selectedCashAsset ? '' : cashAssets[0])}
          >
            {!addedCashAssets.length ? 'Add Cash Asset' : 'Add additional Cash Asset'}
          </button>
        )}
        <div className={`panel ${selectedCashAsset ? 'active' : ''}`}>
          <select onChange={(e) => handleCashAssetChange(e.target.value)} value={selectedCashAsset}>
            <option value="">{selectedCashAsset ? 'Change Type' : 'Select Type'}</option>
            {cashAssets.map((asset, index) => (
              <option key={index} value={asset}>
                {asset}
              </option>
            ))}
          </select>
          {selectedCashAsset && (
            <div className="input-group">
              <input
                type="number"
                placeholder="Enter Amount"
                onChange={handleCashAssetAmountChange}
                value={cashAssetAmount}
                onKeyDown={handleKeyDown}
              />
              <button
                style={{
                  backgroundColor: 'var(--accent-color)',
                  color: 'var(--primary-color)',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontSize: '16px',
                }}
                onClick={handleAddCashAsset}
              >
                Add
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NonCashAssetsStep = ({ nonCashAssets }) => {
  const {
    selectedNonCashAsset,
    setSelectedNonCashAsset,
    nonCashAssetAmount,
    handleNonCashAssetChange,
    handleNonCashAssetAmountChange,
    handleAddNonCashAsset,
    addedNonCashAssets,
    handleDeleteNonCashAsset,
  } = useFormContext();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddNonCashAsset();
    }
  }

  return (
    <div className="step">
      <h2
        style={{
          backgroundColor: 'var(--primary-color)',
          color: 'var(--secondary-color)',
          padding: 20,
          borderRadius: 10
        }}
      >
        Non-Cash Assets
      </h2>
      <div className="accordion">
        <p style={{ padding: 10 }}>Non-cash assets means all forms of property, land or valuables owned by you and your spouse or partner, whether within the
          Republic of Ireland or outside</p>
        {addedNonCashAssets.length > 0 && (
          <div className="added-items">
            {addedNonCashAssets.map((asset, index) => (
              <div key={index} className="added-item">
                <p>
                  {asset.type}: {`€${Number(asset.amount).toLocaleString()}`}
                  <button onClick={() => handleDeleteNonCashAsset(index)}>Delete</button>
                </p>
              </div>
            ))}
          </div>
        )}
        {!selectedNonCashAsset && (
          <button
            style={{ backgroundColor: 'var(--accent-color)', borderRadius: 10, textAlign: 'center', color: 'var(--primary-color)' }}
            className={`accordion-btn ${selectedNonCashAsset ? 'active' : ''}`}
            onClick={() => setSelectedNonCashAsset(selectedNonCashAsset ? '' : nonCashAssets[0])}
          >
            {!addedNonCashAssets.length ? 'Add Non-Cash Asset' : 'Add additional Non-Cash Asset'}
          </button>
        )}
        <div className={`panel ${selectedNonCashAsset ? 'active' : ''}`}>
          <select onChange={(e) => handleNonCashAssetChange(e.target.value)} value={selectedNonCashAsset}>
            <option value="">{selectedNonCashAsset ? 'Change Type' : 'Select Type'}</option>
            {nonCashAssets.map((asset, index) => (
              <option key={index} value={asset}>
                {asset}
              </option>
            ))}
          </select>
          {selectedNonCashAsset && (
            <div className="input-group">
              <input
                type="number"
                placeholder="Enter Amount"
                onChange={handleNonCashAssetAmountChange}
                value={nonCashAssetAmount}
                onKeyDown={handleKeyDown}
              />
              <button
                onClick={handleAddNonCashAsset}
                style={{
                  backgroundColor: 'var(--accent-color)',
                  color: 'var(--primary-color)',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontSize: '16px',
                }}
              >
                Add
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Calculation = ({ jointAssessment }) => {
  const [calculatedAmount, setCalculatedAmount] = useState();
  const { addedNonCashAssets, addedCashAssets, addedIncomes } = useFormContext();
  const [accessibleIncomePercentage, setAccessibleIncomePercentage] = useState(jointAssessment ? 0.4 : 0.8);
  const [assetsPercentage, setAssetsPercentage] = useState(jointAssessment ? 0.0375 : 0.075);
  const [exclusionAmount, setExclusionAmount] = useState(jointAssessment ? 72000 : 36000)

  useEffect(() => {
    setAccessibleIncomePercentage(jointAssessment ? 0.4 : 0.8);
    setAssetsPercentage(jointAssessment ? 0.0375 : 0.075);
    setExclusionAmount(jointAssessment ? 72000 : 36000)
  }, [jointAssessment])

  const makeCalculation = () => {;
    const totalIncome = addedIncomes.reduce((accumulator, currentObject) => {
      const value = parseInt(currentObject.amount);
      return accumulator + value;
    }, 0);

    const totalCashAssets = addedCashAssets.reduce((accumulator, currentObject) => {
      const value = parseInt(currentObject.amount);
      return accumulator + value;
    }, 0);

    const totalNonCashAssets = addedNonCashAssets.reduce((accumulator, currentObject) => {
      const value = parseInt(currentObject.amount);
      return accumulator + value;
    }, 0);

    let totalAssets = totalCashAssets + totalNonCashAssets;

    if (totalAssets > exclusionAmount) {
      totalAssets = totalAssets - exclusionAmount;
    }
    
    totalAssets = totalAssets * assetsPercentage;
    const total = totalIncome * accessibleIncomePercentage;
    setCalculatedAmount(total + (totalAssets / 52));
  }

  return (
    <>
      {calculatedAmount >= 0 && (
        <div style={{ textAlign: 'center', padding: '20px 0', backgroundColor: 'var(--accent-color-background)' }}>
          <h3 style={{ color: 'var(--primary-color)' }}>
          Calculated weekly contribution of <br /><span style={{ fontSize: 40, color: 'var(--primary-color)'}}>{`€${calculatedAmount.toFixed(2)}`}</span>
        </h3>
        </div>
      )}
      <button
        style={{ backgroundColor: 'var(--primary-color)', borderRadius: 0, textAlign: 'center', color: 'var(--accent-color)', marginBottom: 0 }}
        className="accordion-btn"
        onClick={makeCalculation}
      >
        {calculatedAmount ? 'Re-calculate My Fair Deal' : 'Calculate My Fair Deal'}
      </button>
    </>
  )
}

const Switch = ({ isOn, handleToggle, colorOne, colorTwo }) => {
  return (
    <div style={{
      padding: '16px 16px 0 16px',
      backgroundColor: 'var(--background-color)',
      marginBottom: 16,
    }}>
      <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'var(--primary-color)',
          padding: 20,
          borderRadius: 10
        }}>
        <h2 style={{ color: 'var(--secondary-color)'}}>Couple</h2>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="switch-checkbox"
          id={`switch`}
          type="checkbox"
        />
        <label
          style={{ background: isOn ? colorOne : colorTwo }}
          className="switch-label"
          htmlFor={`switch`}
        >
          <span className={`switch-button`} />
        </label>
      </div>
      <div style={{ padding: 10 }}>
        <p style={{ color: 'var(--primary-color)' }}>
          How much you pay depends on if you're single or in a couple. If you have a spouse or partner, the financial assessment will look at both your incomes and assets.
        </p>
      </div>
    </div>
  );
};

const Form = () => {
  const [checked, setChecked] = React.useState(false);
  const incomes = [
    'Department of Social Protection pension/allowance/benefit',
    'Any other non-Irish pension',
    'Occupational pension',
    'Employment, trade, profession or vocation',
    'Income from rentals',
    'Income from holding an office or directorship',
    'Income from fees, commissions, dividends, interest or income of a similar nature',
    'Rental income from Principal Private Residence (PPR)',
    'Rental Income from non-PPR',
    'Payments under a settlement, covenant, estate or a payment in respect of maintenance',
    'Income from royalties and annuities',
    'Income that was transferred from you to another person within the last 5 years',
    'Farming/Business Income',
    'Other'
  ];

  const cashAssets = ['Bank', 'Credit Union', 'Post Office', 'stocks, shares, bonds or securities', 'Money loaned by you to another person', 'Cash assets transferred to another person', 'Other'];

  const nonCashAssets = ['Home (if owned or part owned)', 'Property', 'Land', 'Farms', 'Businesses', 'Overseas land and property', 'Transferred property', 'Other'];

  return (
    <HelmetProvider>
      <Helmet>
        <title>My Fair Deal - Nursing Home Support Scheme</title>
        <meta
          name="description"
          content="Fair Deal provides financial support for those in long-term nursing home care. You pay part of the nursing home fees and the government will pay the balance"
        />
      </Helmet>
      <FormProvider>
        <div>
          <img
            alt='my-fair-deal'
            src={header}
            style={{
              height: 'auto',
              width: '100%',
              maxHeight: 150,
            }} />
        </div>
        {/* <h1
          style={{
            backgroundColor: 'var(--primary-color)',
            color: 'var(--secondary-color)',
            padding: 20,
            marginBottom: '0px',
          }}
        >
          My Fair Deal
        </h1> */}
        <div
          style={{
            textAlign: 'left',
            padding: 40,
            margin: '0 auto',
            backgroundColor: 'var(--accent-color-background)'
          }}>
          <h2 style={{ color: 'var(--primary-color)' }}>What is the Nursing Homes
            Support Scheme?</h2>
          <p style={{ color: 'var(--primary-color)' }}>
            If you need care in a nursing home, you
            can apply for financial support to help
            pay for the cost of this care through
            the Nursing Homes Support Scheme
          </p>
          <p style={{ color: 'var(--primary-color)' }}>The scheme is also known as Fair Deal.
            You pay part of the nursing home fees
            and the government pay the balance</p>
          <h2 style={{ color: 'var(--primary-color)' }}>Who can apply for the
            scheme?</h2>
          <p style={{ color: 'var(--primary-color)' }}>
            Anyone who may need long-term
            nursing home care can apply. You must
            be ordinarily resident in the Republic
            of Ireland, which means that you have
            been living here for at least 1 year or you
            intend to live here for at least 1 year.

          </p>
        </div>
        <div
          style={{
            textAlign: 'left',
            padding: 40,
            margin: '0 auto',
            backgroundColor: 'var(--secondary-color)'
          }}>
            <h2 style={{ color: 'var(--primary-color)' }}>What is My Fair Deal?</h2>
            <p style={{ color: 'var(--primary-color)' }}>My Fair Deal is a calculator that will give you a high level estimate as to what you can expect to contribute should you need care in a nursing home</p>
            <p style={{ color: 'var(--primary-color)' }}>Calculations are based off the HSE Nursing Home Support Scheme official website <a rel="noreferrer" target='_blank' href='https://www2.hse.ie/services/schemes-allowances/fair-deal-scheme/about/'>here.</a> My Fair Deal is in no way affiliated with the HSE and the calculations produced are for guidance only.</p>
          </div>
        <div className="form-container">
        {/* <h2 style={{ color: 'var(--primary-color)' }}>Calculator</h2> */}
          <Switch
            isOn={checked}
            handleToggle={() => setChecked(!checked)}
            colorOne="#5CD2C6"
            colorTwo="#EF476F"
          />
          <IncomesStep incomes={incomes} />
          <CashAssetsStep cashAssets={cashAssets} />
          <NonCashAssetsStep nonCashAssets={nonCashAssets} />
        </div>
        <Calculation jointAssessment={checked} />
      </FormProvider>
    </HelmetProvider>
  );
};

export default Form;
