import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export const useFormContext = () => {
  return useContext(FormContext);
};

export const FormProvider = ({ children }) => {
  const [selectedIncome, setSelectedIncome] = useState('');
  const [incomeAmount, setIncomeAmount] = useState('');
  const [isRentalIncome, setIsRentalIncome] = useState(false);

  const [selectedCashAsset, setSelectedCashAsset] = useState('');
  const [cashAssetAmount, setCashAssetAmount] = useState('');

  const [selectedNonCashAsset, setSelectedNonCashAsset] = useState('');
  const [nonCashAssetAmount, setNonCashAssetAmount] = useState('');

  const [addedIncomes, setAddedIncomes] = useState([]);
  const [addedCashAssets, setAddedCashAssets] = useState([]);
  const [addedNonCashAssets, setAddedNonCashAssets] = useState([]);

  const handleIsRentalIncomeChange = (value) => {
    setIsRentalIncome(value);
  }

  const handleIncomeChange = (value) => {
    setSelectedIncome(value);
  };

  const handleCashAssetChange = (value) => {
    setSelectedCashAsset(value);
  };

  const handleNonCashAssetChange = (value) => {
    setSelectedNonCashAsset(value);
  };

  const handleIncomeAmountChange = (e) => {
    setIncomeAmount(e.target.value);
  };

  const handleCashAssetAmountChange = (e) => {
    setCashAssetAmount(e.target.value);
  };

  const handleNonCashAssetAmountChange = (e) => {
    setNonCashAssetAmount(e.target.value);
  };

  const handleAddIncome = () => {
    if (selectedIncome && incomeAmount) {
      setAddedIncomes([...addedIncomes, { type: selectedIncome, amount: incomeAmount }]);
      setSelectedIncome('');
      setIncomeAmount('');
    }
  };

  const handleAddCashAsset = () => {
    if (selectedCashAsset && cashAssetAmount) {
      setAddedCashAssets([...addedCashAssets, { type: selectedCashAsset, amount: cashAssetAmount }]);
      setSelectedCashAsset('');
      setCashAssetAmount('');
    }
  };

  const handleAddNonCashAsset = () => {
    if (selectedNonCashAsset && nonCashAssetAmount) {
      setAddedNonCashAssets([...addedNonCashAssets, { type: selectedNonCashAsset, amount: nonCashAssetAmount }]);
      setSelectedNonCashAsset('');
      setNonCashAssetAmount('');
    }
  };

  const handleDeleteIncome = (index) => {
    const updatedIncomes = addedIncomes.filter((_, i) => i !== index);
    setAddedIncomes(updatedIncomes);
  };

  const handleDeleteCashAsset = (index) => {
    const updatedCashAssets = addedCashAssets.filter((_, i) => i !== index);
    setAddedCashAssets(updatedCashAssets);
  };

  const handleDeleteNonCashAsset = (index) => {
    const updatedNonCashAssets = addedNonCashAssets.filter((_, i) => i !== index);
    setAddedNonCashAssets(updatedNonCashAssets);
  };

  return (
    <FormContext.Provider
      value={{
        selectedIncome,
        setSelectedIncome,
        incomeAmount,
        setIncomeAmount,
        selectedCashAsset,
        setSelectedCashAsset,
        cashAssetAmount,
        setCashAssetAmount,
        selectedNonCashAsset,
        setSelectedNonCashAsset,
        nonCashAssetAmount,
        setNonCashAssetAmount,
        addedIncomes,
        setAddedIncomes,
        addedCashAssets,
        setAddedCashAssets,
        addedNonCashAssets,
        setAddedNonCashAssets,
        handleIncomeChange,
        handleIncomeAmountChange,
        handleAddIncome,
        handleCashAssetChange,
        handleCashAssetAmountChange,
        handleAddCashAsset,
        handleNonCashAssetChange,
        handleNonCashAssetAmountChange,
        handleAddNonCashAsset,
        handleDeleteIncome,
        handleDeleteCashAsset,
        handleDeleteNonCashAsset,
        handleIsRentalIncomeChange,
        isRentalIncome,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
